import React from 'react';
import { useNavigate } from 'react-router-dom';
function CheckBCS(){
  const navigate = useNavigate();
   
     function handleClick(){
      navigate("/checkingBCS");
    }
    return (
       
        <>
         <div className='my-[100px] '>
                  <h1 className='text-[42px] font-semibold text-left text-white'>Ready to go?</h1>
                  <p className='text-[15px] text-left text-white mt-2 font-normal'>You have installed Visualware BCS and are rearing to go? Then, click on the Check for BCS button below.</p>
                  <button onClick={handleClick} className="bg-transparent border-2 border-white hover:bg-white hover:text-black px-5 py-3 text-base font-semibold text-center text-white mt-16 duration-500">Check BCS</button><br></br>
                  <button className="text-base font-semibold text-center text-white mt-11" onClick={handleClick}>By pass BCS Check</button>
              </div>
              <div className='my-[100px]'>
                  <h3 className='text-[17px] font-semibold text-left text-white'>Please Note</h3>
                 <div className='pl-5'>
                 <ul className='list-outside list-disc'>
                    <li>Presence of some network devices such as firewalls can interfere with test results.</li>
                    <li>You will be able to email the assessment results to your IT department or to your NFON reseller.</li>
                    <li>The utility will provide you with a Test ID, which is a reference that you will need to provide should you or your reseller need to contact our sales or support teams, so your test results can be located in order to advise you further if necessary.</li>
                  </ul>
                 </div>
              </div>
        </>
            
    )
}
export default CheckBCS;