import React from "react";
import { useNavigate,useSearchParams } from 'react-router-dom';

function LandingPage(){
    const navigate = useNavigate();
    let [searchParams] = useSearchParams();

    if(searchParams.get('ticketID')){
        sessionStorage.setItem("ticketID", searchParams.get('ticketID')+ window.location.hash);
    }
   
    function handleClick(){
        navigate("/download");
    }

    return (<>
                <div className='my-[100px] '>
                  <h1 className='text-[42px] font-semibold text-left text-white'>Is your network ready for the Cloudya Apps?</h1>
                  <p className='text-[15px] text-left text-white mt-2 font-normal'>Assess your network readiness the Cloudya Apps (Web and Desktop Apps). Initiate the assessment below to know the network quality to support calls to and from your PC and the expected voice quality.</p>
                  <button onClick={handleClick} className="bg-N_Sage hover:bg-N_Sage_Hover px-5 py-3 text-base font-semibold text-center text-black mt-5">Start the test</button>
              </div>
              <div className='my-[100px]'>
                  <h3 className='text-[17px] font-semibold text-left text-white'>Please Note</h3>
                 <div className='pl-5'>
                 <ul className='list-outside list-disc'>
                    <li>Presence of some network devices such as firewalls can interfere with test results.</li>
                    <li>You will be able to email the assessment results to your IT department or to your NFON reseller.</li>
                    <li>The utility will provide you with a Test ID, which is a reference that you will need to provide should you or your reseller need to contact our sales or support teams, so your test results can be located in order to advise you further if necessary.</li>
                  </ul>
                 </div>
              </div>
    </>
             
    )
}
export default LandingPage;