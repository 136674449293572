import React from "react";
import { useNavigate } from "react-router-dom";
function DownloadPrompt() {
  const navigate = useNavigate();
  const [ShowAllOptions, setShowAllOptions] = React.useState(false);
  function getOs() {
    const os = ["Windows", "Mac"]; // add your OS values
    return os.find((v) => navigator.appVersion.indexOf(v) >= 0);
  }
  const os = getOs();

  function handleClick() {
    setShowAllOptions(true);
  }

  function handleDownload(event) {
    switch (event.currentTarget.dataset.id) {
      case "windows-app":
        window.open("downloads/BCSWinApp.exe");
        break;
      case "windows-service":
        window.open("downloads/bcs_setup.exe");
        break;
      case "mac-app":
        window.open("downloads/MacBCS.app.zip");
        break;
      case "mac-service":
        window.open("downloads/bcs_mac.dmg");
        break;
      default:
        break;
    }
  }
  function handleNext() {
    navigate("/checkBCS");
  }
  return (
    <>
      <div className="my-[100px]">
        <h1 className="text-[42px] font-semibold text-left text-white">
          Before getting started: Download Visualware BCS
        </h1>
        <div className="flex mt-4">
          {os === "Mac" ? (
            <p className="text-[15px] text-left text-white font-normal">
              For Mac OS 10.14+ (Mojave +)
            </p>
          ) : (
            <p className="text-[15px] text-left text-white font-normal">
              For Windows 8+
            </p>
          )}
          <button
            className="text-base font-semibold text-center text-white ml-5 "
            onClick={handleClick}
          >
            Not on {os} ? See other Options
          </button>
        </div>
        {os === "Mac" || ShowAllOptions ? (
          <>
            {" "}
            <div className="flex mt-4 items-center">
              <img
                className="w-10"
                src="icons/icons8-apple-logo.svg"
                alt="Apple Logo"
              ></img>
              <h3 className="text-[15px] font-semibold text-left text-white ml-5">
                Download Options for Mac
              </h3>
            </div>
            <div className="flex mt-6">
              <button
                className="bg-N_Sage hover:bg-N_Sage_Hover px-5 py-3 text-base font-semibold text-center text-black duration-500"
                data-id={'mac-app'} onClick={handleDownload}
              >
                Download BCS App
              </button>
{/*               <button
                className="bg-N_Sage hover:bg-N_Sage_Hover px-5 py-3 text-base font-semibold text-center text-black ml-5 duration-500"
                data-id={'mac-service'} onClick={handleDownload}
              >
                Download BCS Service
              </button> */}
            </div>{" "}
          </>
        ) : (
          ""
        )}

        {os === "Windows" || ShowAllOptions ? (
          <>
            {" "}
            <div className="flex mt-11 items-center">
              <img
                className="w-10"
                src="icons/icons8-windows-10.svg"
                alt="Windows Logo"
              ></img>
              <h3 className="text-[15px] font-semibold text-left text-white ml-5">
                Download Options for Windows
              </h3>
            </div>
            <div className="flex mt-6">
              <button
                className="bg-N_Sage hover:bg-N_Sage_Hover px-5 py-3 text-base font-semibold text-center text-black duration-500"
                data-id={'windows-app'} onClick={handleDownload}
              >
                Download BCS App
              </button>
{/*               <button
                className="bg-N_Sage hover:bg-N_Sage_Hover px-5 py-3 text-base font-semibold text-center text-black ml-5 duration-500"
                data-id={'windows-service'} onClick={handleDownload}
              >
                Download BCS Service
              </button> */}
            </div>{" "}
          </>
        ) : (
          ""
        )}

        <button className="text-base font-semibold text-center text-white mt-6">
          Installation Help?
        </button>
        <div>
          <button
            className="bg-transparent border-2 border-white hover:bg-white hover:text-black px-5 py-3 text-base font-semibold text-center text-white mt-16 duration-500"
            onClick={handleNext}
          >
            I have BCS Installed and Running
          </button>
        </div>
      </div>
      <div className="my-[100px]">
        <h3 className="text-[17px] font-semibold text-left text-white">
          Please Note
        </h3>
        <div className="pl-5">
          <ul className="list-outside list-disc">
            <li>
              The BCS plugin, like PDF Reader, Zoom, or Go-To-Meeting, provides
              a powerful and, crucially, accurate platform for connection
              testing. There are many so called Speed Tests out there today but
              the reality is they are not accurate. Browser only tests that do
              not require a plugin are simply not capable of performing tests
              like VoIP, Route or Capacity. In short, they do not measure the
              true user experience of the Internet. Visualware BCS does.
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
export default DownloadPrompt;
